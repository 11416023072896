<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="card-title">By User Type</h4>
        <div class="d-flex overall-count">
          <div class="mr-2">Overall Count</div>
          <switches
            v-model="overallCount"
            type-bold="true"
            color="success"
            class="m-0"
          ></switches>
        </div>
      </div>
      <!-- Bar Chart -->
      <VueApexCharts
        class="apex-charts"
        height="350"
        type="bar"
        dir="ltr"
        :series="series"
        :options="chartOptions"
        :key="key"
      ></VueApexCharts>
    </div>
  </div>
</template>
<script>
import appConfig from "@/app.config";
import VueApexCharts from "vue-apexcharts";
import Switches from "vue-switches";
import filterMixins from "../../../mixins/filterData";

export default {
  props: ["filters"],
  mixins: [filterMixins],
  components: {
    VueApexCharts,
    Switches,
  },
  data() {
    return {
      title: "Live Registrations",
      MemberTypeCount: {},
      series: [
        {
          name: "",
          data: [],
        },
      ],
      overallCount: false,
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        labels: [""],
        stroke: {
          width: [3],
          curve: "smooth",
        },
        colors: ["#5664d2", "#1cbb8c"],
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        noData: {
          text: "Loading...",
        },
        grid: {
          borderColor: "#5664d2",
        },
        xaxis: {
          categories: [
            "Ayush Doctors",
            "Nurses",
            "Others",
            "PG Students",
            "Practicing Doctors",
            "UG Students",
          ],
        },
      },
      key: 0,
      loading: true,
    };
  },
  created() {
    // this.init();
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.getMemberTypeCount();
      },
    },
    overallCount() {
      this.getMemberTypeCount();
    },
  },
  methods: {
    init() {
      // this.loading = true;
      // this.loading = false;
    },
    async getMemberTypeCount() {
      this.loading = true;
      const searchfiltersParams = this.getCleanFilteredData(this.filters);
      try {
        if (this.overallCount == true) {
          const response = await this.$http.get(
            appConfig.api_base_url + "/users/member-types/count?overall=true",
            {
              params: searchfiltersParams,
            }
          );
          this.MemberTypeCount = response.data.member_type_users[0];
        } else {
          const response = await this.$http.get(
            appConfig.api_base_url + "/users/member-types/count",
            {
              params: searchfiltersParams,
            }
          );
          this.MemberTypeCount = response.data.member_type_users[0];
        }
        let array = [
          this.MemberTypeCount.ayush_doctors_count,
          this.MemberTypeCount.nurses_count,
          this.MemberTypeCount.others_count,
          this.MemberTypeCount.pg_students_count,
          this.MemberTypeCount.practising_doctors_count,
          this.MemberTypeCount.ug_students_count,
        ];
        this.series.map((item) => {
          item.data = array;
        });
        this.key += 1;
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.getMemberTypeCount();
  },
};
</script>
