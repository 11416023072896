<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex justify-content-between mb-4">
        <h4 class="card-title">Drop Out Rate</h4>
        <!-- Spline Area chart -->
        <div class="">
          <b-button-group size="sm">
            <b-button
              :pressed="true"
              variant="primary"
              @click="getDropOutRateBy(7)"
              >7 days</b-button
            >
            <b-button
              :pressed="false"
              variant="primary"
              @click="getDropOutRateBy(15)"
              >15 days</b-button
            >
            <!-- <b-button :pressed="true" variant="primary" @click="getDropOutRate By(29)">This Month</b-button> -->
            <b-button
              :pressed="false"
              variant="primary"
              @click="getMonthlyDropOutRate()"
              >Month on Month</b-button
            >
          </b-button-group>
        </div>
      </div>
      <VueApexCharts
        class="apex-charts"
        height="350"
        type="area"
        dir="ltr"
        :series="series"
        :options="chartOptions"
        :key="key"
      ></VueApexCharts>
    </div>
  </div>
</template>
<script>
import appConfig from "@/app.config";
import VueApexCharts from "vue-apexcharts";
import filterMixins from "../../../mixins/filterData";
import moment from "moment";

export default {
  props: ["filters"],
  mixins: [filterMixins],
  components: {
    VueApexCharts,
  },
  data() {
    return {
      title: "Live Registrations",
      series: [
        {
          name: "Drop Out Users",
          data: [],
        },
      ],
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false,
            zoomin: false,
          },
        },
      },
      chartOptions: {
        toolbar: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 3,
        },
        colors: ["#ff0000", "#34c38f"],
        xaxis: {
          type: "date",
          categories: [],
        },
        grid: {
          borderColor: "#f1f1f1",
        },
        tooltip: {
          x: {
            format: "yy-mm-dd",
          },
        },
      },
      key: 0,
      loading: true,
    };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.getDropOutRateBy(7);
      },
    },
  },
  created() {
    // this.init();
  },
  computed: {
    btnStates() {
      return this.buttons.map((btn) => btn.state);
    },
  },
  methods: {
    init() {
      // this.loading = true;
      // this.loading = false;
    },
    getFormatedDate(date, type = "date") {
      if (type == "date") {
        return moment(date).format("DD MMMM");
      } else {
        return moment(date).format("MMMM");
      }
    },
    async getDropOutRate() {
      const searchfiltersParams = this.getCleanFilteredData(this.filters);
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/users/dropOutRate",
          {
            params: searchfiltersParams,
          }
        );
        this.series[0].data = response.data.n_days_drop_out.map(
          (n) => n.total_count
        );
        this.chartOptions.xaxis.categories = response.data.n_days_drop_out.map(
          (n) => this.getFormatedDate(n.date)
        );
        this.key += 1;
      } catch (err) {
        console.log(err);
      }
    },
    async getDropOutRateBy(val) {
      const searchfiltersParams = this.getCleanFilteredData(this.filters);
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/users/dropOutRate?day_diff=" + val,
          {
            params: searchfiltersParams,
          }
        );
        this.series[0].data = response.data.n_days_drop_out.map(
          (n) => n.total_count
        );
        this.chartOptions.xaxis.categories = response.data.n_days_drop_out.map(
          (n) => this.getFormatedDate(n.date)
        );
        this.key += 1;
      } catch (err) {
        console.log(err);
      }
    },
    async getMonthlyDropOutRate() {
      const searchfiltersParams = this.getCleanFilteredData(this.filters);
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/users/monthlyDropOutRate",
          {
            params: searchfiltersParams,
          }
        );
        this.series[0].data = response.data.month_on_month_drop_out.map(
          (n) => n.total_count
        );
        this.chartOptions.xaxis.categories =
          response.data.month_on_month_drop_out.map((n) =>
            this.getFormatedDate(n.month_year, "month")
          );
        this.key += 1;
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.getDropOutRateBy(7);
  },
};
</script>
