<template>
  <div>
    <div class="card register-grid">
      <div class="card-body p-1">
        <h4 class="card-title">Current Registrations</h4>
        <h3 class="mb-0">{{ count.count }}</h3>
      </div>
      <template v-if="statsData[0].subvalue">
        <div class="card-body border-left p-1">
          <div
            class="badge font-size-14"
            :class="
              statsData[0].subvalue > 0
                ? 'badge-soft-success'
                : 'badge-soft-danger'
            "
          >
            <i
              class="mdi"
              :class="
                statsData[0].subvalue > 0 ? 'mdi-menu-up' : 'mdi-menu-down'
              "
            ></i>
            {{ statsData[0].subvalue }} %
          </div>
          <div class="text-muted">{{ statsData[0].subtext }}</div>
        </div>
      </template>
    </div>
    <div class="card register-grid">
      <div class="card-body p-1">
        <h4 class="card-title">Current Prime Registrations</h4>
        <h3 class="mb-0">{{ count.prime_count }}</h3>
      </div>
      <template v-if="statsData[1].subvalue">
        <div class="card-body p-1 border-left">
          <div
            class="badge font-size-14"
            :class="
              statsData[1].subvalue > 0
                ? 'badge-soft-success'
                : 'badge-soft-danger'
            "
          >
            <i
              class="mdi"
              :class="
                statsData[1].subvalue > 0 ? 'mdi-menu-up' : 'mdi-menu-down'
              "
            ></i>
            {{ statsData[1].subvalue }} %
          </div>
          <div class="text-muted">{{ statsData[1].subtext }}</div>
        </div>
      </template>
    </div>
    <div class="card">
      <div class="card-body p-2 border-top">
        <h4 class="card-title">Registrations so far!</h4>
        <h3 class="mb-0">{{ count.total_count }}</h3>
      </div>
      <div class="card-body p-2">
        <h4 class="card-title">Prime Registrations so far!</h4>
        <h3 class="mb-0">{{ count.total_prime_count }}</h3>
      </div>
    </div>
  </div>
</template>
<style scoped>
.register-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
<script>
import appConfig from "@/app.config";
import filterMixins from "../../../mixins/filterData";

export default {
  props: ["filters"],
  mixins: [filterMixins],
  data() {
    return {
      count: 0,
      statsData: [
        {
          subvalue: 0,
          subtext: "From Previous Period",
        },
        {
          subvalue: 0,
          subtext: "From Previous Period",
        },
      ],
    };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.getCount();
      },
    },
  },
  methods: {
    async getCount() {
      const searchfiltersParams = this.getCleanFilteredData(this.filters);
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/users/count",
          {
            params: searchfiltersParams,
          }
        );
        this.count = response.data;
        this.statsData[0].subvalue =
          this.count.previous_period_count !== 0
            ? (
                ((this.count.count - this.count.previous_period_count) /
                  this.count.previous_period_count) *
                100
              ).toFixed(2)
            : "0";
        this.statsData[0].subvalue = !isNaN(this.statsData[0].subvalue)
          ? this.statsData[0].subvalue
          : "0";

        this.statsData[1].subvalue =
          this.count.previous_period_prime_count !== 0
            ? (
                ((this.count.prime_count -
                  this.count.previous_period_prime_count) /
                  this.count.previous_period_prime_count) *
                100
              ).toFixed(2)
            : "0";
        this.statsData[1].subvalue = !isNaN(this.statsData[1].subvalue)
          ? this.statsData[1].subvalue
          : "0";
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.getCount();
  },
};
</script>
