<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="card-title">Cities</h4>
        <div class="d-flex overall-count">
          <div class="mr-2">Overall Count</div>
          <switches
            v-model="overallCount"
            type-bold="true"
            color="success"
            class="m-0"
          ></switches>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show&nbsp;
              <b-form-select
                v-model="perPage"
                size="sm"
                :options="pageOptions"
              ></b-form-select
              >&nbsp;entries
            </label>
          </div>
        </div>
        <!-- Search -->
        <div class="col-sm-12 col-md-6">
          <div
            id="tickets-table_filter"
            class="dataTables_filter text-md-right"
          >
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div>
        <!-- End search -->
      </div>
      <JsonExcel
        class="btn pr-0 d-flex justify-content-end"
        :data="formattedCities"
        name="members_cities.csv"
        type="csv">
        <i class="mdi mdi-download"></i>
        Download
      </JsonExcel>
      <div class="table-responsive">
        <b-table
          :items="formattedCities"
          :fields="fields"
          responsive="sm"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template v-slot:cell(city)="row">
            <div>{{ row.value != "" ? row.value : "Unknown" }}</div>
          </template>
        </b-table>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_pa ginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
              ></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import appConfig from "@/app.config";
import filterMixins from "../../../mixins/filterData";
import Switches from "vue-switches";
import JsonExcel from "vue-json-excel";

export default {
  props: ["filters"],
  mixins: [filterMixins],
  components: {
    Switches,
    JsonExcel
  },
  data() {
    return {
      cities: [],
      overallCount: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "cities",
      sortDesc: false,
      fields: [
        { key: "city", sortable: true, label: "City" },
        { key: "total_count", sortable: true, label: "Total" },
        { key: "total_referred_doctors", sortable: true, label: "Referred Users" },
        { key: "total_verified_doctors", sortable: true, label: "Verified Users" },
        { key: "total_referred_and_verified_doctors", sortable: true, label: "Referred & Verified Users" },
        { key: "total_prime_count", sortable: true, label: "Total Prime" },
      ],
    };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.getCitiesList();
      },
    },
    overallCount() {
      this.getCitiesList();
    },
  },
  computed: {
    rows() {
      return this.cities.length;
    },
    formattedCities(){
      return this.cities.map(c => {
        if(c.city == '' || c.city == null){
          c.city = 'Unknown';
        }
        return c
      });
    }
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getCitiesList() {
      const searchfiltersParams = this.getCleanFilteredData(this.filters);
      try {
        if (this.overallCount == true) {
          const response = await this.$http.get(
            appConfig.api_base_url + "/users/demographics/count?overall=true",
            {
              params: searchfiltersParams,
            }
          );
          this.cities = response.data.demographics_users;
        } else {
          const response = await this.$http.get(
            appConfig.api_base_url + "/users/demographics/count",
            {
              params: searchfiltersParams,
            }
          );
          this.cities = response.data.demographics_users;
        }
        const compressed = this.cities.reduce((a, c) => {
          const dateIndex = a.findIndex(
            (o) =>
              o.city === c.city &&
              (c.city != null || c.city != "null" || c.city != "")
          );
          if (dateIndex !== -1) {
            a[dateIndex].total_count += c.total_count;
            a[dateIndex].total_prime_count += c.total_prime_count;
            a[dateIndex].total_referred_doctors += c.total_referred_doctors;
            a[dateIndex].total_verified_doctors += c.total_verified_doctors;
            a[dateIndex].total_referred_and_verified_doctors += c.total_referred_and_verified_doctors;
          } else {
            a.push({
              city: c.city,
              total_count: c.total_count,
              total_prime_count: c.total_prime_count,
              total_referred_doctors: c.total_referred_doctors,
              total_referred_and_verified_doctors: c.total_referred_and_verified_doctors,
              total_verified_doctors: c.total_verified_doctors,
            });
          }
          return a;
        }, []);
        compressed.sort((a, b) => {
          if (a.total_count > b.total_count) {
            return -1;
          }
          if (a.total_count < b.total_count) {
            return 1;
          }
          return 0;
        });
        this.cities = compressed;
        this.totalRows = this.cities.length;
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.getCitiesList();
  },
};
</script>
