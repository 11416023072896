<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="card-title">By Device Type</h4>
        <div class="d-flex overall-count">
          <div class="mr-2">Overall Count</div>
          <switches
            v-model="overallCount"
            type-bold="true"
            color="success"
            class="m-0"
          ></switches>
        </div>
      </div>
      <!-- Donut chart -->
      <VueApexCharts
        class="apex-charts"
        height="230"
        dir="ltr"
        :series="series"
        :options="chartOptions"
        :key="key"
      ></VueApexCharts>
    </div>
  </div>
</template>
<script>
import appConfig from "@/app.config";
import VueApexCharts from "vue-apexcharts";
import Switches from "vue-switches";
import filterMixins from "../../../mixins/filterData";

export default {
  props: ["filters"],
  mixins: [filterMixins],
  components: {
    VueApexCharts,
    Switches,
  },
  data() {
    return {
      title: "Live Registrations",
      deviceCount: {},
      overallCount: false,
      series: [],
      chartOptions: {
        chart: {
          type: "donut",
        },
        labels: ["Android", "iOS", "Web"],
        colors: ["#52BE80", "#404040", "#5664D2"],
        dataLabels: {
          enabled: true,
        },
        
        stroke: {
          width: [3],
          curve: "smooth",
        },
        legend: {
          show: true,
          floating: false,
          fontSize: "14px",
          offsetX: 0,
          offsetY: -10,
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                height: 240,
              },
              legend: {
                show: true,
                position: "bottom",
                horizontalAlign: "center",
                verticalAlign: "middle",
                floating: false,
                fontSize: "14px",
                offsetX: 0,
                offsetY: -10,
              },
            },
          },
        ],
      },
      total_calls: 0,
      key: 0,
      loading: true,
    };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.getDeviceCount();
      },
    },
    overallCount() {
      this.getDeviceCount();
    },
  },
  created() {
    // this.init();
  },
  methods: {
    init() {
      // this.loading = true;
      // this.loading = false;
    },
    async getDeviceCount() {
      const searchfiltersParams = this.getCleanFilteredData(this.filters);
      try {
        if (this.overallCount == true) {
          const response = await this.$http.get(
            appConfig.api_base_url + "/users/device-types/count?&overall=true",
            {
              params: searchfiltersParams,
            }
          );
          this.deviceCount = response.data.device_type_users[0];
        } else {
          const response = await this.$http.get(
            appConfig.api_base_url + "/users/device-types/count",
            {
              params: searchfiltersParams,
            }
          );
          this.deviceCount = response.data.device_type_users[0];
        }
        this.series = [
          this.deviceCount.android.total,
          this.deviceCount.ios.total,
          this.deviceCount.web.total,
        ];
        this.key += 1;
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.getDeviceCount();
  },
};
</script>
